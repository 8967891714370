import { createAsyncThunk } from '@reduxjs/toolkit';
export const createApiAsyncThunk = <T> (action: string, apiFunc: Function) => {
  return createAsyncThunk(
    action,
    async (payload: T) => {
      try {
        const response = await apiFunc(payload)
        return response.data
      } catch (err) {
        let error = err as any
        if (error && error.response && error.response.data) {
          return error.response.data
        } else {
          throw err
        }
      }
    }
  )
}