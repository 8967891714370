module.exports = [
  // { 'code': '1', 'country': 'AG' },
  // { 'code': '1', 'country': 'AI' },
  // { 'code': '1', 'country': 'AS' },
  // { 'code': '1', 'country': 'BB' },
  // { 'code': '1', 'country': 'BM' },
  // { 'code': '1', 'country': 'BS' },
  // { 'code': '1', 'country': 'CA' },
  // { 'code': '1', 'country': 'DM' },
  // { 'code': '1', 'country': 'DO' },
  // { 'code': '1', 'country': 'GD' },
  // { 'code': '1', 'country': 'GU' },
  // { 'code': '1', 'country': 'JM' },
  // { 'code': '1', 'country': 'KN' },
  // { 'code': '1', 'country': 'KY' },
  // { 'code': '1', 'country': 'LC' },
  // { 'code': '1', 'country': 'PR' },
  // { 'code': '1', 'country': 'TC' },
  // { 'code': '1', 'country': 'TT' },
  { 'code': '1', 'country': 'US' },
  // { 'code': '1', 'country': 'VC' },
  // { 'code': '1', 'country': 'VG' },
  // { 'code': '1', 'country': 'VI' },
  // { 'code': '7', 'country': 'KZ' },
  { 'code': '7', 'country': 'RU' },
  { 'code': '20', 'country': 'EG' },
  { 'code': '27', 'country': 'ZA' },
  { 'code': '30', 'country': 'GR' },
  { 'code': '31', 'country': 'NL' },
  { 'code': '32', 'country': 'BE' },
  { 'code': '33', 'country': 'FR' },
  { 'code': '34', 'country': 'ES' },
  { 'code': '36', 'country': 'HU' },
  { 'code': '39', 'country': 'IT' },
  { 'code': '40', 'country': 'RO' },
  { 'code': '41', 'country': 'CH' },
  { 'code': '43', 'country': 'AT' },
  { 'code': '44', 'country': 'GB' },
  // { 'code': '44', 'country': 'GG' },
  // { 'code': '44', 'country': 'IM' },
  // { 'code': '44', 'country': 'JE' },
  { 'code': '45', 'country': 'DK' },
  { 'code': '46', 'country': 'SE' },
  { 'code': '47', 'country': 'NO' },
  // { 'code': '47', 'country': 'SJ' },
  { 'code': '48', 'country': 'PL' },
  { 'code': '49', 'country': 'DE' },
  { 'code': '51', 'country': 'PE' },
  { 'code': '52', 'country': 'MX' },
  { 'code': '53', 'country': 'CU' },
  { 'code': '54', 'country': 'AR' },
  { 'code': '55', 'country': 'BR' },
  { 'code': '56', 'country': 'CL' },
  { 'code': '57', 'country': 'CO' },
  { 'code': '58', 'country': 'VE' },
  { 'code': '60', 'country': 'MY' },
  { 'code': '61', 'country': 'AU' },
  // { 'code': '61', 'country': 'CC' },
  // { 'code': '61', 'country': 'CX' },
  // { 'code': '61', 'country': 'HM' },
  { 'code': '62', 'country': 'ID' },
  { 'code': '63', 'country': 'PH' },
  { 'code': '64', 'country': 'NZ' },
  { 'code': '65', 'country': 'SG' },
  { 'code': '66', 'country': 'TH' },
  { 'code': '81', 'country': 'JP' },
  { 'code': '82', 'country': 'KR' },
  { 'code': '84', 'country': 'VN' },
  { 'code': '86', 'country': 'CN' },
  { 'code': '90', 'country': 'TR' },
  { 'code': '91', 'country': 'IN' },
  { 'code': '92', 'country': 'PK' },
  { 'code': '93', 'country': 'AF' },
  { 'code': '94', 'country': 'LK' },
  { 'code': '95', 'country': 'MM' },
  { 'code': '98', 'country': 'IR' },
  { 'code': '212', 'country': 'EH' },
  // { 'code': '212', 'country': 'MA' },
  { 'code': '213', 'country': 'DZ' },
  { 'code': '216', 'country': 'TN' },
  { 'code': '218', 'country': 'LY' },
  { 'code': '220', 'country': 'GM' },
  { 'code': '221', 'country': 'SN' },
  { 'code': '223', 'country': 'ML' },
  { 'code': '224', 'country': 'GN' },
  { 'code': '225', 'country': 'CI' },
  { 'code': '226', 'country': 'BF' },
  // { 'code': '226', 'country': 'BV' },
  { 'code': '227', 'country': 'NE' },
  { 'code': '228', 'country': 'TG' },
  { 'code': '229', 'country': 'BJ' },
  { 'code': '230', 'country': 'MU' },
  { 'code': '231', 'country': 'LR' },
  { 'code': '232', 'country': 'SL' },
  { 'code': '233', 'country': 'GH' },
  { 'code': '234', 'country': 'NG' },
  { 'code': '235', 'country': 'TD' },
  { 'code': '236', 'country': 'CF' },
  { 'code': '237', 'country': 'CM' },
  { 'code': '238', 'country': 'CV' },
  { 'code': '239', 'country': 'ST' },
  { 'code': '240', 'country': 'GQ' },
  { 'code': '242', 'country': 'CG' },
  { 'code': '243', 'country': 'CD' },
  { 'code': '244', 'country': 'AO' },
  { 'code': '245', 'country': 'GW' },
  { 'code': '246', 'country': 'IO' },
  { 'code': '248', 'country': 'SC' },
  { 'code': '249', 'country': 'SD' },
  { 'code': '250', 'country': 'RW' },
  { 'code': '251', 'country': 'ET' },
  { 'code': '252', 'country': 'SO' },
  { 'code': '253', 'country': 'DJ' },
  { 'code': '254', 'country': 'KE' },
  { 'code': '255', 'country': 'TZ' },
  { 'code': '256', 'country': 'UG' },
  { 'code': '257', 'country': 'BI' },
  { 'code': '258', 'country': 'MZ' },
  { 'code': '260', 'country': 'ZM' },
  { 'code': '261', 'country': 'MG' },
  // { 'code': '262', 'country': 'RE' },
  // { 'code': '262', 'country': 'TF' },
  { 'code': '262', 'country': 'YT' },
  { 'code': '263', 'country': 'ZW' },
  { 'code': '264', 'country': 'NA' },
  { 'code': '265', 'country': 'MW' },
  { 'code': '266', 'country': 'LS' },
  { 'code': '267', 'country': 'BW' },
  { 'code': '268', 'country': 'SZ' },
  { 'code': '269', 'country': 'KM' },
  { 'code': '290', 'country': 'SH' },
  { 'code': '291', 'country': 'ER' },
  { 'code': '297', 'country': 'AW' },
  { 'code': '298', 'country': 'FO' },
  { 'code': '299', 'country': 'GL' },
  { 'code': '350', 'country': 'GI' },
  { 'code': '351', 'country': 'PT' },
  { 'code': '352', 'country': 'LU' },
  { 'code': '353', 'country': 'IE' },
  { 'code': '354', 'country': 'IS' },
  { 'code': '355', 'country': 'AL' },
  { 'code': '356', 'country': 'MT' },
  { 'code': '357', 'country': 'CY' },
  { 'code': '358', 'country': 'AX' },
  // { 'code': '358', 'country': 'FI' },
  { 'code': '359', 'country': 'BG' },
  { 'code': '370', 'country': 'LT' },
  { 'code': '371', 'country': 'LV' },
  { 'code': '372', 'country': 'EE' },
  { 'code': '373', 'country': 'MD' },
  { 'code': '374', 'country': 'AM' },
  { 'code': '375', 'country': 'BY' },
  { 'code': '376', 'country': 'AD' },
  { 'code': '377', 'country': 'MC' },
  { 'code': '378', 'country': 'SM' },
  { 'code': '380', 'country': 'UA' },
  { 'code': '381', 'country': 'RS' },
  { 'code': '382', 'country': 'ME' },
  { 'code': '385', 'country': 'HR' },
  { 'code': '386', 'country': 'SI' },
  { 'code': '387', 'country': 'BA' },
  { 'code': '389', 'country': 'MK' },
  { 'code': '420', 'country': 'CZ' },
  { 'code': '421', 'country': 'SK' },
  { 'code': '423', 'country': 'LI' },
  // { 'code': '500', 'country': 'FK' },
  { 'code': '500', 'country': 'GS' },
  { 'code': '502', 'country': 'GT' },
  { 'code': '503', 'country': 'SV' },
  { 'code': '504', 'country': 'HN' },
  { 'code': '505', 'country': 'NI' },
  { 'code': '506', 'country': 'CR' },
  { 'code': '507', 'country': 'PA' },
  { 'code': '508', 'country': 'PM' },
  { 'code': '509', 'country': 'HT' },
  // { 'code': '590', 'country': 'BL' },
  { 'code': '590', 'country': 'GP' },
  { 'code': '591', 'country': 'BO' },
  { 'code': '592', 'country': 'GY' },
  { 'code': '593', 'country': 'EC' },
  { 'code': '594', 'country': 'GF' },
  { 'code': '595', 'country': 'PY' },
  { 'code': '597', 'country': 'SR' },
  { 'code': '598', 'country': 'UY' },
  { 'code': '599', 'country': 'AN' },
  // { 'code': '599', 'country': 'MF' },
  { 'code': '670', 'country': 'TL' },
  // { 'code': '672', 'country': 'AQ' },
  { 'code': '672', 'country': 'NF' },
  { 'code': '673', 'country': 'BN' },
  { 'code': '674', 'country': 'NR' },
  { 'code': '675', 'country': 'PG' },
  { 'code': '676', 'country': 'TO' },
  { 'code': '677', 'country': 'SB' },
  { 'code': '678', 'country': 'VU' },
  { 'code': '679', 'country': 'FJ' },
  { 'code': '680', 'country': 'PW' },
  { 'code': '681', 'country': 'WF' },
  { 'code': '682', 'country': 'CK' },
  { 'code': '683', 'country': 'NU' },
  { 'code': '685', 'country': 'WS' },
  { 'code': '686', 'country': 'KI' },
  { 'code': '687', 'country': 'NC' },
  { 'code': '688', 'country': 'TV' },
  { 'code': '689', 'country': 'PF' },
  { 'code': '690', 'country': 'TK' },
  { 'code': '691', 'country': 'FM' },
  { 'code': '692', 'country': 'MH' },
  { 'code': '850', 'country': 'KP' },
  { 'code': '852', 'country': 'HK' },
  { 'code': '853', 'country': 'MO' },
  { 'code': '855', 'country': 'KH' },
  { 'code': '856', 'country': 'LA' },
  { 'code': '872', 'country': 'PN' },
  { 'code': '880', 'country': 'BD' },
  { 'code': '886', 'country': 'TW' },
  { 'code': '960', 'country': 'MV' },
  { 'code': '961', 'country': 'LB' },
  { 'code': '963', 'country': 'SY' },
  { 'code': '964', 'country': 'IQ' },
  { 'code': '965', 'country': 'KW' },
  { 'code': '966', 'country': 'SA' },
  { 'code': '967', 'country': 'YE' },
  { 'code': '968', 'country': 'OM' },
  { 'code': '970', 'country': 'PS' },
  { 'code': '971', 'country': 'AE' },
  { 'code': '972', 'country': 'IL' },
  { 'code': '973', 'country': 'BH' },
  { 'code': '974', 'country': 'QA' },
  { 'code': '975', 'country': 'BT' },
  { 'code': '976', 'country': 'MN' },
  { 'code': '977', 'country': 'NP' },
  { 'code': '992', 'country': 'TJ' },
  { 'code': '993', 'country': 'TM' },
  { 'code': '994', 'country': 'AZ' },
  { 'code': '995', 'country': 'GE' },
  { 'code': '996', 'country': 'KG' },
  { 'code': '998', 'country': 'UZ' },
  { 'code': '39066', 'country': 'VA' }
]
